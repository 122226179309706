<template>
  <div class="bg-gray-50 min-h-screen">
    <header-component></header-component>
    <div class="max-w-3xl mx-auto p-8">
      <h1 class="text-3xl font-bold text-orange-500 mb-6">ユーザー設定</h1>
      <form @submit.prevent="updateUser" class="space-y-4">
        <div>
          <h2 class="text-lg font-semibold text-gray-700 mb-2">基本情報</h2>
          <label class="block text-sm font-medium text-gray-700">名前</label>
          <div v-if="!isEditing.studentName">
            <span>{{ userData.studentName }}</span>
            <button type="button" @click="editField('studentName')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <input v-model="userData.studentName" type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" required />
            <button type="button" @click="saveField('studentName')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">保護者の名前</label>
          <div v-if="!isEditing.parentName">
            <span>{{ userData.parentName }}</span>
            <button type="button" @click="editField('parentName')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <input v-model="userData.parentName" type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" required />
            <button type="button" @click="saveField('parentName')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">メールアドレス</label>
          <div v-if="!isEditing.email">
            <span>{{ userData.email }}</span>
            <button type="button" @click="editField('email')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <input v-model="userData.email" type="email" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" required />
            <button type="button" @click="saveField('email')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">電話番号</label>
          <div v-if="!isEditing.phonenumber">
            <span>{{ userData.phonenumber }}</span>
            <button type="button" @click="editField('phonenumber')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <input v-model="userData.phonenumber" type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" required />
            <button type="button" @click="saveField('phonenumber')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">生年月日</label>
          <div v-if="!isEditing.dayOfBirth">
            <span>{{ userData.dayOfBirth }}</span>
            <button type="button" @click="editField('dayOfBirth')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <input v-model="userData.dayOfBirth" type="date" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" required />
            <button type="button" @click="saveField('dayOfBirth')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">郵便番号</label>
          <div v-if="!isEditing.zip">
            <span>{{ userData.zip }}</span>
            <button type="button" @click="editField('zip')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <input v-model="userData.zip" type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" required />
            <button type="button" @click="saveField('zip')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">都道府県</label>
          <div v-if="!isEditing.address_1">
            <span>{{ userData.address_1 }}</span>
            <button type="button" @click="editField('address_1')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <input v-model="userData.address_1" type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" required />
            <button type="button" @click="saveField('address_1')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">市区町村</label>
          <div v-if="!isEditing.address_2">
            <span>{{ userData.address_2 }}</span>
            <button type="button" @click="editField('address_2')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <input v-model="userData.address_2" type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" required />
            <button type="button" @click="saveField('address_2')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700">住所</label>
          <div v-if="!isEditing.address_3">
            <span>{{ userData.address_3 }}</span>
            <button type="button" @click="editField('address_3')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <input v-model="userData.address_3" type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" required />
            <button type="button" @click="saveField('address_3')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>
        <h2 class="text-lg font-semibold text-gray-700 mb-2">決済情報</h2>
        <label class="block text-sm font-medium text-gray-700">支払用カード</label>
        <!-- 横並び -->
        <div class="flex items-center space-x-2">
          <div v-if="userData.hasPaymentMethod">
          <span>設定済み</span>
        </div>
        <div v-else>
          <span>未設定</span>
        </div>
        <button @click="goToStripe" class="ml-2 text-blue-500 text-sm">設定</button>
        </div>
        <h2 class="text-lg font-semibold text-gray-700 mb-2">宿泊イベントに向けた情報</h2>
        <div>
          <label class="block text-sm font-medium text-gray-700">緊急連絡先</label>
          <div v-if="!isEditing.emergency_contact">
            <span>{{ userData.emergency_contact }}</span>
            <button type="button" @click="editField('emergency_contact')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <input v-model="userData.emergency_contact" type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"/>
            <button type="button" @click="saveField('emergency_contact')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">アレルギー</label>
          <div v-if="!isEditing.allergies">
            <span>{{ userData.allergies }}</span>
            <button type="button" @click="editField('allergies')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <input v-model="userData.allergies" type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500"/>
            <button type="button" @click="saveField('allergies')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">生活や健康についてスタッフと共有すべき事項</label>
          <div v-if="!isEditing.healthcare_problem">
            <span>{{ userData.healthcare_problem }}</span>
            <button type="button" @click="editField('healthcare_problem')" class="ml-2 text-sm text-blue-500">編集</button>
          </div>
          <div v-else>
            <textarea v-model="userData.healthcare_problem" type="text" class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" placeholder="些細なことでも結構ですので、ご心配なことがあればご記入ください。" ></textarea>
            <button type="button" @click="saveField('healthcare_problem')" class="ml-2 text-sm text-green-500">保存</button>
          </div>
        </div>
        
        <button type="submit" class="w-full inline-flex justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
          更新
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import HeaderComponent from '../components/Header.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UserSettings',
  components: {
    HeaderComponent
  },
  data() {
    return {
      userData: {
        studentName: '',
        parentName: '',
        email: '',
        phonenumber: '',
        dayOfBirth: '',
        zip: '',
        address_1: '',
        address_2: '',
        address_3: '',
        hasPaymentMethod: false,
        userportal: '',
        allergies: '',
        healthcare_problem: '',
        emergency_contact: '',
        hokensho:''
        
      },
      isEditing: {
        studentName: false,
        parentName: false,
        email: false,
        phonenumber: false,
        dayOfBirth: false,
        zip: false,
        address_1: false,
        address_2: false,
        address_3: false,
        allergies: false,
        healthcare_problem: false,
        emergency_contact: false,
        hokensho: false

      }
    };
  },
  computed: {
    ...mapGetters({
      uid: 'auth/userId'
    })
  },
  async created() {
    try {
      const response = await axios.get(process.env.VUE_APP_BASE_URL+'/user', {
        params: {
          uid: this.uid
        }
      });
      this.userData = response.data.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      alert('ユーザー情報の取得に失敗しました');
    }
  },
  methods: {
    editField(field) {
      this.isEditing[field] = true;
    },
    saveField(field) {
      this.isEditing[field] = false;
    },
    async updateUser() {
      try {
        console.log(this.userData.allergies);
        await axios.post(process.env.VUE_APP_BASE_URL+'/updateuser', this.userData);
        alert('ユーザー情報が更新されました');
      } catch (error) {
        console.error('Error updating user data:', error);
        alert('ユーザー情報の更新に失敗しました');
      }
    },
    goToStripe() {
      
      window.location.href = this.userData.userportal;
    }

  }
};
</script>

<style scoped>
/* 必要に応じてスタイルを追加 */
</style>
