import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Schedule from '@/views/Schedule.vue';
import store from '@/store';
import Reservation from '@/views/Reservation.vue';
import Register from '@/views/Register.vue';
import UserSettings from '@/views/UserSetting.vue';
import EventEntry from '@/views/EventEntry.vue';
import FreeTrial from '@/views/FreeTrial.vue';
import LINEComponented from '@/views/LINEComponented.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: Schedule,
    meta: { requiresAuth: true }
  },
  {
    path: '/reservation',
    name: 'Reservation',
    component: Reservation,
    meta: { requiresAuth: true }
  },
  {
    path: '/event',
    name: 'EventEntry',
    component: EventEntry,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'UserSettings',
    component: UserSettings,
    meta: { requiresAuth: true }
  },
  {
    path: '/free-trial',
    name: 'FreeTrial',
    component: FreeTrial,
  },
  {
    path: '/line',
    name: 'LINE',
    component: LINEComponented,
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['auth/isLoggedIn'];
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  // 未ログインでログインが必要なページにアクセスした場合、ログインページにリダイレクト
  if (requiresAuth && !isLoggedIn) {
    next({ name: 'Login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});


export default router;
