<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-50">
    <h1 class="text-2xl font-bold text-gray-800 mb-4">LINEユーザー連携</h1>
    <div v-if="loading" class="text-gray-500">LINEユーザーIDを設定しています...</div>
    <div v-else>
      <p v-if="successMessage" class="text-green-600">{{ successMessage }}</p>
      <p v-if="errorMessage" class="text-red-500">{{ errorMessage }}</p>
      <router-link to="/" class="text-blue-500 underline">ホームへ戻る</router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import liff from '@line/liff';
import { mapGetters } from 'vuex';


export default {
  name: 'LINEComponented',
  data() {
    return {
      loading: true,
      successMessage: '',
      errorMessage: ''
    };
  },
  computed: {
    ...mapGetters({
      uid: 'auth/userId',
      isLoggedIn: 'auth/isLoggedIn', // ログイン状態を確認
    })
  },
  async created() {
    try {
      // LIFF初期化
      await liff.init({ liffId: '2003032062-LeGeaBdW' });
      
      // LIFFログインしているか確認
      if (!liff.isLoggedIn()) {
        liff.login(); // 未ログインの場合ログインにリダイレクト
        return;
      }

      // プロフィール取得
      const profile = await liff.getProfile();
      const lineUserId = profile.userId; // LIFFで取得したLINEのユーザーID
      const response = await axios.get(process.env.VUE_APP_BASE_URL + '/user', {
        params: {
          uid: this.uid
        }
      });
      const user = response.data.data;
      user.line_user_id = lineUserId;

      await axios.post(process.env.VUE_APP_BASE_URL+'/updateuser', user);
      this.successMessage = 'LINEユーザーIDが正常に設定されました！';
    } catch (error) {
      console.error('LINEユーザーID設定エラー:', error);
      this.errorMessage = 'LINEユーザーIDの設定に失敗しました。再度お試しください。';
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style scoped>
/* 必要に応じてスタイルを追加 */
</style>
